footer {
  background-color: #00A4EF;
  padding: 80px 0;
  color: #ffffff;
}

footer .box {
  padding: 0 20px;
  color: white;
}

footer h1 {
  margin: 0;
  font-size: 25px;
  font-weight: 800;
  width: max-content;
}

footer .logo {
  color: white;
  display:flex;
  align-items:center;
  gap:5px;
  margin-bottom: 20px;
}
footer h2 {
  font-size: 20px;
}

footer .row {
  justify-content: center;
}

footer p {
  font-size: 16px;
  margin-bottom: 20px;
  opacity: 1;
}

footer h2 {
  margin-bottom: 20px;
}

footer ul {
  padding: 0;
}

footer li {
  margin-bottom: 10px;
  opacity: 1;
}